<template>
    <properties-block  v-bind="$props" v-on="$listeners">
        <b-alert show variant="white" class="text-center px-1">
            <div class="f-500 text-primary" v-tr>
                The Footer applies to the entire website|Le bas de page s'applique à tout le site
            </div>
        </b-alert>

        <properties-component-text title="Links Title|Titre des liens" :value="block.linksTitle" v-bind="componentProps" no-binding/>
        <properties-component-navigation-links @edit-navigation="$emit('edit-navigation')" title="Links|Liens" :value="block.links" v-bind="componentProps" no-binding/>
        <properties-component-text title="Social Title|Titre réseaux sociaux" :value="block.socialLinksTitle" v-bind="componentProps" no-binding/>
        <properties-component-social-links title="Social Links|Liens sociaux" :value="block.socialLinks" v-bind="componentProps" no-binding/>
        <properties-component-image title="Logo" :value="block.logo" v-bind="componentProps" edit-background no-binding category="logo"/>
        <properties-component-text title="Copyright|Mention légale" :value="block.copyright" v-bind="componentProps" no-binding/>
    </properties-block>
</template>

<script>
import PropertiesComponentButton from "@/components/editor/properties/components/properties-component-button.vue";
import PropertiesComponentImage from "@/components/editor/properties/components/properties-component-image.vue";
import PropertiesComponentNavigationLinks from "@/components/editor/properties/components/properties-component-navigation-links.vue";
import PropertiesComponentSocialLinks from "@/components/editor/properties/components/properties-component-social-links.vue";
import PropertiesComponentText from "@/components/editor/properties/components/properties-component-text.vue";
import PropertiesBlock from "@/components/editor/properties/blocks/properties-block.vue";

// properties-block-footer #hot-reload-debug
export default {
    name: `properties-block-footer`,
    components: {PropertiesComponentNavigationLinks, PropertiesComponentSocialLinks, PropertiesComponentImage, PropertiesComponentButton, PropertiesComponentText, PropertiesBlock},
    extends: PropertiesBlock
}
</script>

<style lang="scss" scoped>
</style>
